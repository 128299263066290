<div id="container">
  <header>
    <mat-toolbar class="mat-primary">
      <span style="flex: 0 0 60px"
        ><img src="./assets/img/pura-white-40.png" alt="Fusisoft"
      /></span>
      <a mat-button routerLink="/home">Cadmus PURA</a>

      <button
        type="button"
        mat-button
        [matMenuTriggerFor]="itemMenu"
        *ngIf="logged && itemBrowsers"
      >
        Items
      </button>
      <mat-menu #itemMenu>
        <a mat-menu-item routerLink="/items">Items</a>
        <a
          mat-menu-item
          *ngFor="let entry of itemBrowsers"
          [routerLink]="'item-browser/' + getItemBrowserRoute(entry.id)"
          >{{ entry.value }}</a
        >
      </mat-menu>
      <ng-container *ngIf="logged && !itemBrowsers">
        <a mat-button routerLink="/items">Items</a>
      </ng-container>

      <a mat-button routerLink="/search" *ngIf="logged">Search</a>

      <!-- profile menu -->
      <ng-container *ngIf="user && user.roles.includes('admin')">
        <button type="button" mat-button [matMenuTriggerFor]="profileMenu">
          Profile
        </button>
        <mat-menu #profileMenu>
          <a mat-menu-item routerLink="/flags"> Flags </a>
          <a mat-menu-item routerLink="/thesauri"> Thesauri </a>
        </mat-menu>
      </ng-container>

      <span class="tb-fill-remaining-space"></span>

      <div *ngIf="logged" fxLayout="row" fxLayoutAlign="start center">
        <img
          alt="avatar"
          [src]="user?.email | gravatar : 32"
          [alt]="user?.userName"
        />
        <mat-icon
          class="small-icon"
          *ngIf="user && user?.roles?.includes('admin')"
          title="admin"
          >build</mat-icon
        >
        <mat-icon
          class="small-icon"
          *ngIf="user && !user?.emailConfirmed"
          title="You must verify your email address! Please check your mailbox {{
            user.email
          }}"
          >feedback</mat-icon
        >
        <!-- user menu -->
        <button type="button" mat-button [matMenuTriggerFor]="userMenu">
          User
        </button>
        <mat-menu #userMenu>
          <a mat-menu-item routerLink="/reset-password">Reset password</a>
        </mat-menu>

        <!-- admin menu -->
        <button
          type="button"
          *ngIf="user && user.roles.includes('admin')"
          mat-button
          [matMenuTriggerFor]="adminMenu"
        >
          Admin
        </button>
        <mat-menu #adminMenu>
          <a mat-menu-item routerLink="/manage-users">Manage users</a>
          <a mat-menu-item routerLink="/register-user">Register user</a>
        </mat-menu>
      </div>

      <!-- login -->
      <button type="button" *ngIf="!logged" mat-icon-button routerLink="/login">
        <mat-icon>login</mat-icon>
      </button>
      <!-- logout -->
      <button type="button" *ngIf="logged" mat-icon-button (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </mat-toolbar>
  </header>

  <main>
    <router-outlet></router-outlet>
  </main>

  <footer>
    <div layout="row" layout-align="center center">
      <p>
        This project has received funding from the European Research Council
        (ERC) under the European Union's Horizon 2020 research and innovation
        programme (grant agreement No 865817): 2021-01-01 - 2025-12-31 - version
        {{ version }}
      </p>
      <p>
        <a href="mailto:olga.tribulato@unive.it">Olga Tribulato</a>,
        <a rel="noopener" href="https://www.unive.it/pag/27720" target="_blank"
          >Department of Humanities</a
        >, Malcanton Marcorà, Dorsoduro 3484/D, Calle Contarini, 30123 Venice.
      </p>
    </div>
  </footer>
</div>
