<div>
  <div class="banner">
    <img
      class="banner-img"
      srcset="
        ./assets/img/banner-1024.jpg 1024w,
        ./assets/img/banner-512.jpg   512w
      "
      sizes="(max-width: 600px) 512px, 1024px"
    />
  </div>
  <article>
    <h2>Welcome</h2>
    <h3>PURA - PURism in Antiquity</h3>
    <p>
      Welcome to the project editing dashboard. Access to this site is
      restricted to team members.
    </p>
    <p>The Cadmus PURA editor online resources are:</p>
    <ul>
      <li>
        <a
          href="https://github.com/vedph/cadmus_pura_doc"
          target="_blank"
          rel="noopener"
          >documentation</a
        >
      </li>
      <li>
        <a
          rel="noopener"
          href="https://github.com/vedph/cadmus_pura"
          target="_blank"
          >core</a
        >: models specific to the PURA project.
      </li>
      <li>
        <a
          rel="noopener"
          href="https://github.com/vedph/cadmus_pura_api"
          target="_blank"
          >web API</a
        >: the PURA web API, powering this application.
      </li>
      <li>
        <a
          href="https://github.com/vedph/cadmus_pura_app"
          target="_blank"
          rel="noopener"
          >web app</a
        >: this web application.
      </li>
    </ul>
    <p *ngIf="!logged" style="margin-bottom: 8px">
      <a mat-mini-fab class="mat-primary" routerLink="/login"
        ><mat-icon>login</mat-icon></a
      ><span style="margin-left: 8px">please login</span>
    </p>
  </article>
  <div id="logos">
    <img src="assets/img/pura-white-128.png" alt="PURA" />
    <a rel="noopener" href="http://www.ilc.cnr.it/" target="_blank"
      ><img src="assets/img/ilc-128.png" alt="ILC"
    /></a>
    <a rel="noopener" href="https://www.clarin-it.it/it" target="_blank"
      ><img src="assets/img/clarin-128.png" alt="CLARIN"
    /></a>
  </div>
</div>
